import { memo } from "react";
import { sanitizeImageUrl } from "../../../utils/sanity.utils";
import { BlogPageTranslations } from "../../../model/blog";
import Link from "next/link";
import { toClassName } from "../../../utils/style.utils";

interface Props {
  slug: string;
  blogContent: BlogPageTranslations;
  className: string;
}

export const CardMedia = memo<Props>(({ slug, blogContent, className }) => {
  return (
    <Link href={`/blog/${slug}`}>
      <div
        className={toClassName(
          className,
          "flex flex-col ml-8 rounded-[20px] mt-6 pt-6 pl-6 pr-6 pb-10 lg:pb-12 bg-day-base-base-200"
        )}
      >
        <div className="grow">
          <img
            className="rounded-[15px] aspect-[3/2]"
            src={
              blogContent.image &&
              sanitizeImageUrl(blogContent.image?.asset?._ref)
            }
            alt={`Cover of article ${blogContent.title}`}
          />
          <div className="pl-4 pr-4">
            <div
              // eslint-disable-next-line
              dangerouslySetInnerHTML={{
                __html: blogContent.type,
              }}
              className="text-day-base-base-400 pt-8 pb-6"
            />
            <div
              // eslint-disable-next-line
              dangerouslySetInnerHTML={{
                __html: blogContent.title,
              }}
              className="text-[20px] leading-[124%] lg:text-[28px] lg:leading-[1.2] pb-12"
            />
          </div>
        </div>
        <div className="flex flex-wrap pl-4 pr-4">
          {blogContent.tags.map((item, index) => (
            <div
              key={index}
              className="mr-2 w-fit rounded-[30px] border border-solid border-day-base-base-400 mt-2 pt-2 pb-2 pl-8 pr-8 uppercase text-[16px] leading-[16px] text-day-base-base-400"
              // eslint-disable-next-line
              dangerouslySetInnerHTML={{
                __html: item.title,
              }}
            />
          ))}
        </div>
      </div>
    </Link>
  );
});
