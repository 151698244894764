import { memo } from "react";
import { BootcampCard } from "../../components/common/card-course/card";
import { Bootcamp } from "../../model/bootcamp";
import { SECTION_ID } from "../../constants/constants";

interface Props {
  data: Bootcamp[];
  startTitle: string;
}

export const BootcampFeedSection = memo<Props>(({ data, startTitle }) => {
  return (
    <section
      id={SECTION_ID.BOOTCAMPS}
      className="ml-auto mr-auto max-w-[1440px] pl-5 pr-5 lg:pl-20 lg:pr-20 xxxxl:pl-0 xxxxl:pr-0 grid-cols-2 gap-[10px] lg:gap-[16px] md:grid"
    >
      {data
        .filter((bootcamp) => {
          return (
            bootcamp._id !== "01ccf510-54d8-45aa-8c80-ad0b5a94c452" &&
            bootcamp._id !== "03b39b26-c950-45fd-81ba-74b1395e2d45"
          );
        })
        .sort((a, b) => a.ranking - b.ranking)
        .map((bootcamp) => (
          <BootcampCard
            startTitle={startTitle}
            key={bootcamp._id}
            data={bootcamp}
          />
        ))}
    </section>
  );
});
