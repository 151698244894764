import { memo } from "react";
import { Bootcamp } from "../../../model/bootcamp";
import Link from "next/link";
import { sanitizeImageUrl } from "../../../utils/sanity.utils";
import { getCourseStartDate } from "../../../utils/date.utils";
import { getStartCourseDate, isToday } from "../../../utils/date.utils";
import { useCoverVideo } from "../../../hooks/useCoverVideo";
import { useRouter } from "next/router";
import { getCourseStatus } from "../../../utils/course-utils";

interface Props {
  data: Bootcamp;
  startTitle: string;
}

export const BootcampCard = memo<Props>(({ data, startTitle }) => {
  const { locale } = useRouter();
  const todayDate = new Date();

  const isCourseToday = isToday(getStartCourseDate(todayDate, data.ranking));
  const { onMouseOver, onMouseOut, videoRef } = useCoverVideo();
  const { isOptimism, isBlast } = getCourseStatus(data._id);

  return (
    <Link href={`bootcamp/${data.slug}`}>
      <div
        onMouseOver={onMouseOver}
        onMouseOut={onMouseOut}
        className="relative z-10 mb-10 h-full cursor-pointer rounded-[20px] bg-night-background pt-4 pl-4 pr-4 pb-8 lg:pt-6 lg:pl-6 lg:pr-6 lg:pb-12 lg:mt-0"
      >
        <div className="flex h-full flex-col">
          <div className="grow">
            <div className="absolute flex items-center top-8 left-8 z-10 max-w-max rounded-[40px] bg-white pl-6 pr-6 pt-[3px] pb-[3px] text-[15px] lg:text-[16px] lg:leading-[130%] text-night-base-base-100 lg:top-[20px] lg:left-[20px] lg:pl-6 lg:pr-6 lg:pt-2 lg:pb-2 lg:text-[16px]">
              {isCourseToday && (
                <div className="h-4 w-4 bg-lime rounded-full mr-3" />
              )}{" "}
              {isBlast ? "" : startTitle}{" "}
              {getCourseStartDate(
                isOptimism,
                isBlast,
                "Free",
                todayDate,
                data.ranking,
                locale
              )}
            </div>
            <div>
              <video
                ref={videoRef}
                loop
                controls={false}
                muted
                className="w-full rounded-[16px]"
                poster={sanitizeImageUrl(data.image.asset._ref)}
              >
                <source
                  src={`https://website-videos-for-bootcamps.s3.eu-central-1.amazonaws.com/videos/${data.slug}.mp4`}
                  type="video/mp4"
                />
                Your browser does not support the video tag.
              </video>
            </div>
            <h4
              className="h4 mt-8 text-night-base-base-900 lg:mt-12 pl-4 lg:pl-6"
              // eslint-disable-next-line
              dangerouslySetInnerHTML={{
                __html: data.title,
              }}
            />
            <p
              // eslint-disable-next-line
              dangerouslySetInnerHTML={{
                __html: data.introMainPage,
              }}
              className="pt-4 pb-12 pl-4 pr-4 text-[15px] lg:text-[18px] leading-[22px] lg:leading-[132%] text-night-base-base-800 lg:pb-18 lg:pl-6 lg:pr-6 lg:pt-9"
            />
          </div>
          <div className="align-center flex flex-wrap items-center justify-start pl-4 pr-4 lg:pl-6 lg:pr-6 text-[16px]">
            {data.stack.map((item, index) => (
              <p
                key={index}
                className="mr-2 lg:mr-4 mt-2 lg:mt-4 bg-white-opacity-008 text-[15px] md:text-[16px] md:leading-[130%] rounded-[38px] pt-2 pb-2 pl-5 pr-5 uppercase text-night-base-base-800"
                // eslint-disable-next-line
                dangerouslySetInnerHTML={{
                  __html: item.title,
                }}
              />
            ))}
          </div>
        </div>
      </div>
    </Link>
  );
});
