import { memo, useMemo } from "react";
import { CardMedia } from "../../components/common/card-media/cardMedia";
import { SECTION_ID } from "../../constants/constants";
import { Media } from "../../model/main-page-data";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/splide/css/core";
import { ArrowSvgPath } from "../../constants/constants";
import { BlogPageTranslations } from "../../model/blog";
import { useRouter } from "next/router";

interface Props {
  media: Media;
  blog: BlogPageTranslations[];
  blogContent: BlogPageTranslations[];
}

export const MediaSection = memo<Props>(({ media, blog, blogContent }) => {
  const { locale } = useRouter();
  const options = useMemo(
    () => ({
      arrowPath: ArrowSvgPath,
      rewindByDrag: true,
      speed: 600,
      perPage: 3,
      perMove: 1,
      lazyLoad: true,
      type: "loop",
      breakpoints: {
        768: {
          type: "loop",
          fixedWidth: "320px",
          perPage: 1.3,
          perMove: 1,
        },
        1280: {
          type: "loop",
          perPage: 2,
        },
      },
    }),
    []
  );
  return (
    <section id={SECTION_ID.MEDIA} className="relative bottom-[-60px] z-10">
      <div className="pb-15 lg:pb-35">
        <div className="rounded-[30px] pl-6 pr-6 lg:pl-20 lg:pr-20 pt-24 lg:pt-40 bg-gradient-to-b from-day-base-base-100 from-10% to-white to-10%">
          <h2
            // eslint-disable-next-line
            dangerouslySetInnerHTML={{
              __html: media.title,
            }}
            className="text-center text-day-base-base-900"
          />
          <p
            // eslint-disable-next-line
            dangerouslySetInnerHTML={{
              __html: media.subtitle,
            }}
            className="m-auto max-w-[600px] pt-5 text-center text-[16px] leading-[130%] md:text-[18px] md:leading-[124%] text-day-base-base-400 lg:pt-8"
          />
        </div>
        <div className="max-w-[1440px] pt-15 pb-40 md:pb-40 lg:pt-28 lg:pb-[130px] lg:ml-12 lg:mr-15 xxxxl:m-auto">
          <Splide
            options={options}
            className="splide-experts lg:mr-10"
            aria-label="Media slides"
          >
            {[...blogContent]
              .sort(
                (a, b) =>
                  new Date(b.date).getTime() - new Date(a.date).getTime()
              )
              .map((contentItem, index) => {
                const relatedBlog = blog.find((b) => {
                  if (locale === "ru") {
                    return b._id.replace(/__i18n_en$/, "") === contentItem._id;
                  } else {
                    return b._id === contentItem._id;
                  }
                });

                const slug = relatedBlog ? relatedBlog.slug.current : "";

                return (
                  <SplideSlide key={index}>
                    <CardMedia
                      className={
                        "h-auto min-h-[495px] lg:min-h-[598px] xl:min-h-[650px] xxl:min-h-[584px] xxxl:min-h-[568px]"
                      }
                      blogContent={contentItem}
                      slug={`${slug}`}
                    />
                  </SplideSlide>
                );
              })}
          </Splide>
        </div>
      </div>
    </section>
  );
});
