import { memo, useState, useEffect } from "react";
import FirstLineImgUrl from "../assets/1-line.png";
import SecondLineImgUrl from "../assets/2-line.png";
import ThirdLineImgUrl from "../assets/3-line.png";
import { SECTION_ID } from "../../constants/constants";
import { isScreenLessThan } from "../../utils/screen.utils";

export const LinesSection = memo(() => {
  const [currentTranslateX, setCurrentTranslateX] = useState<number>(0);
  const [isAnimated, setIsAnimated] = useState<boolean>(false);

  useEffect(() => {
    function handleResize() {
      if (isScreenLessThan("lg")) {
        setIsAnimated(false);
      }
    }

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const moreThanDesktopSize = 1024 < window.innerWidth;

    const handleScroll = () => {
      const schoolSection = document.getElementById(SECTION_ID.SCHOOL);
      if (!schoolSection) return;

      const schoolSectionBottomPosition =
        schoolSection.getBoundingClientRect().bottom;

      const position = window.pageYOffset;

      if (
        schoolSectionBottomPosition <= window.innerHeight &&
        moreThanDesktopSize
      ) {
        setIsAnimated(true);
      }

      if (isAnimated) {
        setCurrentTranslateX((position / 50) * 4 - 200);
      }
    };

    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isAnimated]);

  return (
    <div className="relative overflow-hidden bg-white pb-24 lg:pb-60 pt-5">
      <div
        className="flex pt-10 pb-10 will-change-transform lg:pb-20 lg:pt-15"
        style={{
          transform: `translateX(-${currentTranslateX}px) rotate(-2deg)`,
        }}
      >
        <img
          className="h-[45px] max-w-[initial] md:h-[50px] xl:h-[80px]"
          src={FirstLineImgUrl.src}
          alt="Line with tech stack"
        />
        <img
          className="h-[45px] max-w-[initial] md:h-[50px] xl:h-[80px]"
          src={FirstLineImgUrl.src}
          alt="Line with tech stack"
        />
      </div>

      <div
        className="flex pb-2 will-change-transform"
        style={{
          transform: `translateX(${currentTranslateX - 400}px)`,
        }}
      >
        <img
          className="h-[45px] max-w-[initial] md:h-[50px] xl:h-[80px]"
          src={SecondLineImgUrl.src}
          alt="Line with tech stack"
        />
        <img
          className="h-[45px] max-w-[initial] md:h-[50px] xl:h-[80px]"
          src={SecondLineImgUrl.src}
          alt="line with tech stack"
        />
      </div>

      <div
        className="flex pt-12 will-change-transform lg:pt-20"
        style={{
          transform: `translateX(-${currentTranslateX}px) rotate(-2deg)`,
        }}
      >
        <img
          className="h-[45px] max-w-[initial] md:h-[50px] xl:h-[80px]"
          src={ThirdLineImgUrl.src}
          alt="line with tech stack"
        />
        <img
          className="h-[45px] max-w-[initial] md:h-[50px] xl:h-[80px]"
          src={ThirdLineImgUrl.src}
          alt="line with tech stack"
        />
      </div>
    </div>
  );
});
