import { memo } from "react";
import { sanitizeImageUrl } from "../../../utils/sanity.utils";
import { toClassName } from "../../../utils/style.utils";
import { ExpectationItem, OffsetType } from "../../../model/main-page-data";

interface Props {
  data: ExpectationItem;
  className?: string;
  offset: OffsetType;
}

export const CardWithExpectation = memo<Props>(
  ({ data: { image, title, description }, offset, className }) => {
    const offsetClassname = {
      [OffsetType.LEFT]: "xl:left-[100px]",
      [OffsetType.RIGHT]: "xl:right-[100px]",
      [OffsetType.NONE]: "",
    };

    const imageClassname = toClassName(
      "relative h-full object-contain",
      offset && offsetClassname[offset]
    );

    return (
      <div
        className={toClassName(
          className,
          "mt-6 md:mt-0 xl:mt-8 flex h-auto flex-col rounded-[20px] bg-day-base-base-100 p-8 md:mb-0 lg:pl-16 lg:pr-16 lg:pt-16 lg:pb-16 xl:mr-8"
        )}
      >
        <div className="relative h-[200px]">
          <img
            className={imageClassname}
            src={image?.asset && sanitizeImageUrl(image.asset._ref)}
            alt={`${title} image`}
          />
        </div>
        <div>
          <h6
            className="pt-15 lg:pt-19"
            // eslint-disable-next-line
            dangerouslySetInnerHTML={{
              __html: title,
            }}
          />
          <p
            // eslint-disable-next-line
            dangerouslySetInnerHTML={{
              __html: description,
            }}
            className="pt-5 text-day-base-base-400 text-[15px] leading-[20px] lg:text-[18px] lg:leading-[132%] lg:pt-6"
          />
        </div>
      </div>
    );
  }
);
