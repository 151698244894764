import { Image } from "./sanity-common";

export interface MainPageData {
  expectations: Expectations;
  media: Media;
  questions: Questions;
  subtitle: string;
  title: string;
  login: string;
  cta: string;
  menu: MenuItem[];
  copyright: Copyright;
  upButtonTitle: string;
  contacts: Contacts;
  seoTitle: string;
}

export interface Contacts {
  list: ListItem[];
}

export interface MenuItem {
  title: string;
  id: string;
  link?: string;
  _key: string;
  list: ListItem;
}

export interface ListItem {
  title: string;
  link?: string;
  _key: string;
}

export interface Copyright {
  title: string;
  firstButton: string;
  secondButton: string;
}

export interface Expectations {
  list: ExpectationItem[];
  subtitle: string;
  title: string;
}

export interface Media {
  list: MediaItem[];
  subtitle: string;
  title: string;
  podcast: Podcast;
}

export interface Podcast {
  title: string;
  subtitle: string;
  episodeTitle: string;
  episodeSubtitle: string;
  allEpisodesLinkTitle: string;
  episodeNumber: string;
  link: string;
  image: Image;
}

export const enum OffsetType {
  LEFT = "left",
  RIGHT = "right",
  NONE = "none",
}

export interface ExpectationItem {
  description: string;
  image?: Image;
  title: string;
  offset: OffsetType;
}

export interface MediaItem {
  _key: string;
  title: string;
  type: string;
  link: string;
  image?: Image;
  tags: Tag[];
}

interface Tag {
  title: string;
}

export interface Questions {
  list: QuestionItem[];
  title: string;
}

export interface QuestionItem {
  description: string;
  image?: Image;
  title: string;
}
