import { memo } from "react";
import { CardWithExpectation } from "../../components/common/сard-with-expectation/card";
import { SECTION_ID } from "../../constants/constants";
import { Expectations, OffsetType } from "../../model/main-page-data";

interface Props {
  expectations: Expectations;
}

export const ExpectationsSection = memo<Props>(({ expectations }) => {
  function getSizeBasedOnIndex(index: number) {
    const small = "xl:w-[440px]";
    const big = "xl:w-[564px]";
    const sizes = [small, big, big, small];

    return sizes[index];
  }

  function getOffsetBasedOnIndex(index: number): OffsetType {
    const offset = [
      OffsetType.NONE,
      OffsetType.LEFT,
      OffsetType.RIGHT,
      OffsetType.NONE,
    ];

    return offset[index];
  }

  return (
    <section
      id={SECTION_ID.SCHOOL}
      className="rounded-t-[30px] pb-18 bg-white lg:pb-45 relative top-[-30px]"
    >
      <h2
        className="pl-13 pr-13 pt-24 text-center lg:pt-20"
        // eslint-disable-next-line
        dangerouslySetInnerHTML={{
          __html: expectations.title,
        }}
      />
      <p
        // eslint-disable-next-line
        dangerouslySetInnerHTML={{
          __html: expectations.subtitle,
        }}
        className="m-auto max-w-[680px] pl-13 pr-13 pt-6 text-center text-[16px] md:text-[18px] leading-[130%] md:leading-[124%] text-day-base-base-400 lg:pt-8"
      />
      <div className="md:grid grid-cols-2 md:gap-[10px] lg:gap-[16px] xl:flex flex-wrap justify-center pt-10 pl-5 pr-5 lg:pt-18 lg:pr-20 lg:pl-20 lg:pt-28 m-auto max-w-[1440px]">
        {expectations.list.map((expectation, index) => (
          <CardWithExpectation
            key={expectation.title}
            offset={getOffsetBasedOnIndex(index)}
            className={getSizeBasedOnIndex(index)}
            data={expectation}
          />
        ))}
      </div>
    </section>
  );
});
