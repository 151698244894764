import Head from "next/head";
import { Navbar } from "../components/common/navbar/navbar";
import { WelcomeScreenSection } from "../sections/welcome/welcome";
import { BootcampFeedSection } from "../sections/bootcamp-feed/bootcamp-feed";
import { ChooseFormSection } from "../sections/choose-form/choose-form";
import { ExpectationsSection } from "../sections/expectations/expectation";
import { LinesSection } from "../sections/lines/lines";
import { ExpertsSection } from "../sections/experts/experts";
import { TwitterFeedBackSection } from "../sections/twitter-feedback/twitter-feedback";
import { MediaSection } from "../sections/media/media";
import { QuestionsSection } from "../sections/questions/questions";
import { CopyrightSection } from "../sections/copyright/copyright";
import { FooterSection } from "../sections/footer/footer";
import { groq } from "next-sanity";
import { Bootcamp } from "../model/bootcamp";
import { MainPageData } from "../model/main-page-data";
import { initApiClient } from "../api/api-client";
import { Experts } from "../model/expert";
import { TwitterFeedback } from "../model/twitter";
import { GetStaticPropsContext } from "next";
import { CommonTranslates } from "../model/common-tranlates";
import { LegalDocuments } from "../model/legal-documents";
import { Canvas } from "@react-three/fiber";
import Plane from "../components/common/shader/shader";
import { BlogPageTranslations } from "../model/blog";
import { ScreenshotSection } from "../sections/screenshot/screenshot";
import { useRouter } from "next/router";
import { Screenshot } from "../model/screenshot";
import { Banner } from "../model/banner";
import { FormCmsData } from "../model/form";
import { TelegramChat } from "../components/common/telegram-chat/telegram-chat";
import { BannerWithDiscount } from "../components/common/banner/banner";

interface Props {
  bootcamps: Bootcamp[];
  mainPageData: MainPageData;
  experts: Experts;
  reviews: TwitterFeedback[];
  commonTranslates: CommonTranslates;
  legalDocuments: LegalDocuments;
  blog: BlogPageTranslations[];
  screenshots: Screenshot;
  banner: Banner;
  blogContent: BlogPageTranslations[];
  forms: FormCmsData;
}

const Index = ({
  bootcamps,
  reviews,
  mainPageData,
  experts,
  commonTranslates,
  legalDocuments,
  screenshots,
  blog,
  forms,
  banner,
  blogContent,
}: Props) => {
  const { locale } = useRouter();

  return (
    <>
      <Head>
        <title>
          Guide DAO | Web3 development courses: Solidity, NFT, DAO, DeFi
        </title>
        <meta name="title" content="Guide DAO" />
        <meta
          name="description"
          content="Web3 development courses: Solidity, NFT, DAO, DeFi"
        />
        <meta property="og:url" content="https://www.guidedao.xyz/" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Guide DAO" />
        <meta property="og:site_name" content="Guide DAO" />
        <meta
          property="og:description"
          content="Web3 development courses: Solidity, NFT, DAO, DeFi"
        />
        <meta
          property="og:image"
          content="https://images.ctfassets.net/r0lccig03c53/2GWFK26RcGtV2Tbfqp39a5/1aebd076aafd2a1f2dbd8ab605bc4d15/2_STAT_DISCORD_SERVER_BANNER_BG.png"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta property="twitter:domain" content="guidedao.xyz" />
        <meta property="twitter:url" content="https://www.guidedao.xyz/" />
        <meta name="twitter:title" content="Guide DAO" />
        <meta
          name="twitter:description"
          content="Web3 development courses: Solidity, NFT, DAO, DeFi"
        />
        <meta
          name="twitter:image"
          content="https://images.ctfassets.net/r0lccig03c53/2GWFK26RcGtV2Tbfqp39a5/1aebd076aafd2a1f2dbd8ab605bc4d15/2_STAT_DISCORD_SERVER_BANNER_BG.png"
        />
      </Head>
      <div className="relative">
        <div className="absolute top-[0px] left-[0px] w-full h-full">
          <Canvas>
            <Plane />
          </Canvas>
        </div>
        <BannerWithDiscount
          forms={forms}
          banner={banner}
          orTitle={commonTranslates.orTitle}
          formId={"Попап с баннера на главной страницы"}
        />

        <Navbar menu={mainPageData.menu} loginBtnTitle={mainPageData.login} />
        <TelegramChat />
        <WelcomeScreenSection
          formId={"Попап с главной страницы / welcome"}
          orTitle={commonTranslates.orTitle}
          forms={forms}
          cta={mainPageData.cta}
          title={mainPageData.title}
          subtitle={mainPageData.subtitle}
        />
      </div>
      <BootcampFeedSection
        startTitle={commonTranslates.startTitle}
        data={bootcamps}
      />
      <div id="main-form" className="relative">
        <div className="absolute top-[-30px] left-[0px] w-full h-[140px] bg-gradient-to-b from-[#070809] z-100"></div>
        <ChooseFormSection
          formId={"Форма консультации с главной страницы"}
          title={forms.consultation.title}
          subtitle={forms.consultation.subtitle}
          placeholderEmail={forms.placeholderEmail}
          placeholderTelegram={forms.placeholderTelegram}
          placeholderName={forms.placeholderName}
          button={forms.consultation.button}
          selfContact={forms.selfContact}
          selfContactLink={forms.selfContactLink}
          successScreenTitle={forms.successScreenTitle}
          successScreenSubtitle={forms.successScreenSubtitle}
          dataSendWaitMessage={forms.dataSendWaitMessage}
          alertErrorMessage={forms.alertErrorMessage}
          fillTheFormMessage={forms.fillTheFormMessage}
          orTitle={commonTranslates.orTitle}
        />
      </div>
      <div className="bg-white rounded-[30px]" id="learning">
        {locale === "ru" && (
          <div className="pt-24 lg:pt-40 pl-5 pr-5 lg:pl-20 lg:pr-20 pb-30 lg:pb-50 md:pb-40 text-center">
            <ScreenshotSection screenshots={screenshots} />
          </div>
        )}
        <div
          aria-label="Experts slides light"
          className={`${locale !== "ru" ? "pt-40" : ""}`}
        >
          <ExpertsSection
            profileTitle={commonTranslates.profileTitle}
            experts={experts}
          />
        </div>
        <ExpectationsSection expectations={mainPageData.expectations} />
        <div className="bg-white rounded-b-[30px]">
          <LinesSection />
        </div>
        <TwitterFeedBackSection reviews={reviews} />
        <MediaSection
          blog={blog}
          blogContent={blogContent}
          media={mainPageData.media}
        />
      </div>
      <QuestionsSection questions={mainPageData.questions} />
      <div className="bg-black bg-cover bg-[left_bottom_20rem] bg-[url('/images/index/footer-bg-mobile.png')] md:bg-[left_top_60rem] lg:bg-[center_top_-10rem] md:bg-[url('/images/index/footer-bg-desktop.png')]">
        <CopyrightSection
          orTitle={commonTranslates.orTitle}
          forms={forms}
          data={mainPageData.copyright}
        />
        <FooterSection
          contacts={mainPageData.contacts}
          upButtonTitle={mainPageData.upButtonTitle}
          menu={mainPageData.menu}
          forms={forms}
          agreementTitle={legalDocuments.agreementTitle}
          privacyTitle={legalDocuments.privacyTitle}
        />
      </div>
    </>
  );
};

export async function getStaticProps({ locale }: GetStaticPropsContext) {
  const apiClient = initApiClient();

  const localeForLegalDocs = locale === "ru" ? "ru" : "en";
  const localeForBlog = locale === "ru" ? "ru" : "en";

  const query = groq`
  {
    "bootcamps": *[_type == "bootcamps" && __i18n_lang == "${locale}"],
    "banner": *[_type == "banner" && __i18n_lang == "${locale}"],
    "mainPageData": *[_type == "mainPage" && __i18n_lang == "${locale}"],
    "experts": *[_type == "experts" && __i18n_lang == "${locale}"],
    "reviews": *[_type == "reviews" && __i18n_lang == "${locale}"],
    "commonTranslates": *[_type == "commonTranslates" && __i18n_lang == "${locale}"],
    "legalDocuments": *[_type == "legalDocuments" && __i18n_lang == "${localeForLegalDocs}"],
    "blog": *[_type == "blog" && __i18n_lang == "en"],
    "blogContent":*[_type == "blog" && __i18n_lang == "${localeForBlog}"],
    "screenshots": *[_type == "screenshots" && __i18n_lang == "ru"],
    "forms": *[_type == "forms" && __i18n_lang == "${locale}"],
  }`;

  const {
    bootcamps,
    mainPageData: [mainPageData],
    banner: [banner],
    legalDocuments: [legalDocuments],
    experts: [experts],
    screenshots: [screenshots],
    forms: [forms],
    commonTranslates: [commonTranslates],
    reviews,
    blog,
    blogContent,
  } = await apiClient.fetch(groq`${query}`);

  return {
    props: {
      bootcamps,
      reviews,
      mainPageData,
      commonTranslates,
      legalDocuments,
      experts,
      screenshots,
      blog,
      forms,
      banner,
      blogContent,
    },
  };
}

export default Index;
