import { useState, useRef, useEffect, useCallback } from "react";

export function useCoverVideo() {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [isPlayingVideo, setIsPlayingVideo] = useState(false);
  const [hasStartedPlaying, setHasStartedPlaying] = useState(false);

  useEffect(() => {
    if (!videoRef.current) {
      return;
    }
    try {
      if (isPlayingVideo) {
        const playPromise = videoRef.current.play();
        if (playPromise !== undefined) {
          playPromise
            .then(() => {
              setHasStartedPlaying(true);
            })
            .catch((error) => {
              console.error(error);
            });
        }
      } else {
        if (hasStartedPlaying) {
          videoRef.current.pause();
        }
      }
    } catch (e) {
      console.error(e);
    }
  }, [isPlayingVideo, hasStartedPlaying]);

  const onMouseOut = useCallback(() => {
    if (hasStartedPlaying) {
      setIsPlayingVideo(false);
    }
  }, [hasStartedPlaying]);

  const onMouseOver = useCallback(() => {
    setIsPlayingVideo(true);
  }, []);

  return {
    videoRef,
    onMouseOver,
    onMouseOut,
  };
}
