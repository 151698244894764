import { extend, useFrame, useThree } from "@react-three/fiber";
import { shaderMaterial } from "@react-three/drei";
import * as THREE from "three";
import { useEffect, useRef, useMemo } from "react";
import { isSSR } from "../../../utils/ssr.utils";

const GradientShaderMaterialMobile = shaderMaterial(
  {
    u_time: 0,
    u_mouse: new THREE.Vector2(),
    u_resolution: new THREE.Vector2(),
    seed: Math.random(),
  },
  `
    varying vec2 v_texcoord;
    void main() {
      v_texcoord = uv;
      gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
    }
    `,
  `
    precision highp float;
  uniform float u_time;
  uniform vec2 u_resolution;
  uniform vec2 u_mouse;
  uniform float seed;
  varying vec2 v_texcoord;
  #define NUM_OCTAVES 5
  
  float rand(vec2 n) { 
      return fract(sin(dot(n, vec2(12.9898, 4.1414))) * 43758.5453);
  }
  
  float noise(vec2 p){
      vec2 ip = floor(p);
      vec2 u = fract(p);
      u = u*u*(3.0-2.0*u);
  
      float res = mix(
          mix(rand(ip),rand(ip+vec2(1.0,0.0)),u.x),
          mix(rand(ip+vec2(0.0,1.0)),rand(ip+vec2(1.0,1.0)),u.x),u.y);
      return res*res;
  }
  
  float fbm(vec2 x) {
      float v = 0.0;
      float a = 0.5;
      vec2 shift = vec2(100);
      // Rotate to reduce axial bias
      mat2 rot = mat2(cos(0.5), sin(0.5), -sin(0.5), cos(0.50));
      for (int i = 0; i < NUM_OCTAVES; ++i) {
          v += a * noise(x);
          x = rot * x * 1.5 + shift;
          a *= 0.5;
      }
      return v;
  }
  
  mat2 rotation2d(float angle) {
      float s = sin(angle);
      float c = cos(angle);
  
      return mat2(
          c, -s,
          s, c
      );
  }
  
  void main(void) {
      vec2 uv = v_texcoord;
  
      vec2 mouse = u_mouse;
      float dist = distance(uv, mouse);
      float strength = smoothstep(0.2, 0.0, dist);
  
      vec3 color1 = vec3(69.0/255.0, 69.0/255.0, 69.0/255.0);
      vec3 color2 = vec3(7.0/255.0, 8.0/255.0, 9.0/255.0);
  
      float grain = rand(100.0 * uv) * mix(0.2, 0.01, strength);
  
      vec2 movement = vec2(u_time * 0.005, u_time * -0.005); // Reduced speed
      movement *= rotation2d(u_time * 0.005); // Reduced speed
  
      float f = fbm(uv + movement + seed);  
      f *= 4.5;
      f += grain;
      f += u_time * 0.1; 
      f = fract(f);
  
      float gap = mix(0.5, 0.03, strength);
      float mixer = smoothstep(0.0, gap, f) - smoothstep(1.0 - gap, 1.0, f);
  
      vec3 color = mix(color1, color2, mixer);
  
      gl_FragColor = vec4(color, 1.0);
  }
  
    `
);

const GradientShaderMaterial = shaderMaterial(
  {
    u_time: 0,
    u_mouse: new THREE.Vector2(),
    u_resolution: new THREE.Vector2(),
    seed: Math.random(),
  },
  `
    varying vec2 v_texcoord;
    void main() {
      v_texcoord = uv;
      gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
    }
    `,
  `
    precision highp float;
  uniform float u_time;
  uniform vec2 u_resolution;
  uniform vec2 u_mouse;
  uniform float seed;
  varying vec2 v_texcoord;
  #define NUM_OCTAVES 5
  
  float rand(vec2 n) { 
      return fract(sin(dot(n, vec2(12.9898, 4.1414))) * 43758.5453);
  }
  
  float noise(vec2 p){
      vec2 ip = floor(p);
      vec2 u = fract(p);
      u = u*u*(3.0-2.0*u);
  
      float res = mix(
          mix(rand(ip),rand(ip+vec2(1.0,0.0)),u.x),
          mix(rand(ip+vec2(0.0,1.0)),rand(ip+vec2(1.0,1.0)),u.x),u.y);
      return res*res;
  }
  
  float fbm(vec2 x) {
      float v = 0.0;
      float a = 0.5;
      vec2 shift = vec2(100);
      mat2 rot = mat2(cos(0.5), sin(0.5), -sin(0.5), cos(0.50));
      for (int i = 0; i < NUM_OCTAVES; ++i) {
          v += a * noise(x);
          x = rot * x * 2.0 + shift;
          a *= 0.5;
      }
      return v;
  }
  
  mat2 rotation2d(float angle) {
      float s = sin(angle);
      float c = cos(angle);
  
      return mat2(
          c, -s,
          s, c
      );
  }
  
  void main(void) {
      vec2 uv = v_texcoord;
  
      vec2 mouse = u_mouse;
      float dist = distance(uv, mouse);
      float strength = smoothstep(0.25, 0.0, dist);
  
      vec3 color1 = vec3(69.0/255.0, 69.0/255.0, 69.0/255.0);
      vec3 color2 = vec3(7.0/255.0, 8.0/255.0, 9.0/255.0);
  
      float grain = rand(100.0 * uv) * mix(0.2, 0.01, strength);
  
      vec2 movement = vec2(u_time * 0.005, u_time * -0.005); 
      movement *= rotation2d(u_time * 0.005); 
  
      float f = fbm(uv + movement + seed);  
      f *= 6.5;
      f += grain;
      f += u_time * 0.2; 
      f = fract(f);
  
      float gap = mix(0.5, 0.01, strength);
      float mixer = smoothstep(0.0, gap, f) - smoothstep(1.0 - gap, 1.0, f);
  
      vec3 color = mix(color1, color2, mixer);
  
      gl_FragColor = vec4(color, 1.0);
  }
  
    `
);

extend({ GradientShaderMaterial });

export default function Plane() {
  const mesh = useRef<THREE.Mesh>(null);
  const { size, set } = useThree();
  const shaderMaterial = useMemo(() => {
    const material = new GradientShaderMaterial();
    const aspectRatio = window.innerWidth / window.innerHeight;
    material.uniforms.u_resolution.value.x = window.innerWidth * aspectRatio;
    material.uniforms.u_resolution.value.y = window.innerHeight;

    return material;
  }, []);

  const shaderMaterialMobile = useMemo(() => {
    const material = new GradientShaderMaterialMobile();
    const aspectRatio = window.innerWidth / window.innerHeight;
    material.uniforms.u_resolution.value.x = window.innerWidth * aspectRatio;
    material.uniforms.u_resolution.value.y = window.innerHeight;

    return material;
  }, []);

  const shaderMaterialRef = useRef(shaderMaterial);
  const shaderMaterialMobileRef = useRef(shaderMaterialMobile);

  useEffect(() => {
    const aspect = size.width / size.height;
    const zoom = 1;
    set({
      camera: new THREE.OrthographicCamera(
        -zoom * aspect,
        zoom * aspect,
        zoom,
        -zoom,
        0,
        1
      ),
    });
    set({
      size: {
        width: window.innerWidth,
        height: window.innerHeight,
        top: 0,
        left: 0,
      },
    });

    const handleMouseMove = (e: MouseEvent) => {
      shaderMaterialRef.current.uniforms.u_mouse.value.x =
        e.clientX / window.innerWidth;
      shaderMaterialRef.current.uniforms.u_mouse.value.y =
        1 - (e.clientY + window.scrollY) / window.innerHeight - 0.08;
    };

    window.addEventListener("mousemove", handleMouseMove);

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  useFrame(({ clock }) => {
    if (mesh.current && mesh.current.material instanceof THREE.ShaderMaterial) {
      mesh.current.material.uniforms.u_time.value = clock.getElapsedTime();
    }
  });

  if (isSSR()) {
    return null;
  }

  const isMobile = window.innerWidth <= 1024;
  const shaderMaterialToUse = isMobile
    ? shaderMaterialMobileRef
    : shaderMaterialRef;

  return (
    <mesh ref={mesh} scale={[size.width, size.height, 1]}>
      <planeGeometry attach="geometry" args={[1, 1]} />
      <primitive
        object={shaderMaterialToUse.current}
        attach="material"
        uniforms={{
          u_resolution: new THREE.Vector2(
            window.innerWidth,
            window.innerHeight
          ),
          ...shaderMaterial.uniforms,
        }}
      />
    </mesh>
  );
}
