import { memo, useState, useCallback } from "react";
import { Button } from "../../components/common/button/button";
import { PopUpBlock } from "../../components/common/pop-up/pop-up";
import { FormCmsData } from "../../model/form";

interface Props {
  subtitle: string;
  title: string;
  cta: string;
  orTitle: string;
  formId: string;
  forms: FormCmsData;
}

export const WelcomeScreenSection = memo<Props>(
  ({ title, cta, subtitle, orTitle, formId, forms }) => {
    const [isContactSubmitPopupShown, setIsContactSubmitPopupShown] =
      useState<boolean>(false);

    const onContactPopupSubmitted = useCallback(() => {
      setIsContactSubmitPopupShown(false);
    }, []);

    const subtitleWrapper = (
      <p
        className="m-auto max-w-[305px] lg:max-w-[820px] text-[15px] leading-[20px] lg:leading-[132%] lg:text-[18px] pt-15 pb-20 lg:pb-20 text-[#D1D8DB]"
        // eslint-disable-next-line
        dangerouslySetInnerHTML={{
          __html: subtitle,
        }}
      ></p>
    );

    return (
      <section className="relative w-full bg-contain items-center pl-10 pr-10 pt-60 text-white lg:pt-0 lg:pl-20 lg:pr-20">
        <div className="absolute bottom-[0px] left-[0px] w-full h-[50px] bg-gradient-to-t from-[#070809] z-100"></div>
        <div
          className={
            isContactSubmitPopupShown ? "block cursor-pointer" : "hidden"
          }
        >
          <PopUpBlock
            formId={formId}
            orTitle={orTitle}
            forms={forms}
            onContactPopupSubmitted={onContactPopupSubmitted}
          />
        </div>
        <div className="text-center pb-[155px] lg:pt-77 lg:pb-66">
          <h1>
            <span
              // eslint-disable-next-line
              dangerouslySetInnerHTML={{ __html: title }}
            />
          </h1>
          {subtitleWrapper}
          <Button
            onClick={() => setIsContactSubmitPopupShown(true)}
            size="m"
            theme="accent"
            className="text-[18px] leading-[132%]"
          >
            <span className="relative top-[-1px]">{cta}</span>
          </Button>
        </div>
      </section>
    );
  }
);
